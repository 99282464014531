//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDgutWy398NAjpEPYJ-s5I9JEbs4JBaCgc",
  authDomain: "qvrsebot.firebaseapp.com",
  projectId: "qvrsebot",
  storageBucket: "qvrsebot.firebasestorage.app",
  messagingSenderId: "771704750675",
  appId: "1:771704750675:web:28278c4b2257be350bbca6",
  measurementId: "G-BR9B5PT0N5"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
